@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap');

html {
  box-sizing: border-box;
  height: 100%;
}

*,
*::before,
*::after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
font,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-weight: inherit;
  font-style: inherit;
  font-size: 100%;
  font-family: inherit;
  vertical-align: baseline;
}

img {
  max-width: 100%;
  display: block;
}

a {
  text-decoration: none;
  color: inherit;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

i {
  margin: 0;
  padding: 0;
}

p {
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth;
}

body {
  height: 100%;
  font-family: "Montserrat", sans-serif;
  overflow-x: hidden;
}

.flex {
  display: flex;
}

.justifyBetween {
  justify-content: space-between;
}

.alignCenter {
  align-items: center;
}

.title {
  text-align: left;
  font-size: 35px;
  font-weight: 700;
}
.slash {
  display: flex;
  width: 20px;
  margin-right: 15px;
  color: #066ac9;
  font-size: 35px;
  font-weight: 600;
}
.header {
  position: absolute;
  top: 0;
  z-index: 3;
  width: 100%;
  max-width: 100%;
}
.header-items {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 10px 0;
}
.header-item {
  padding: 0 10px;
  font-size: 18px;
  border-right: 2px solid #066ac9;
  display: flex;
  align-items: center;
}
.header-item_icon {
  width: 25px; 
  margin-right: 5px;
  display: none;
}
.header-item:first-child {
  border-left: none;
}
.header-item_tel {
  display: none;
} 
.header-email {
  padding-right: 10px;
  font-size: 18px;
  border-right: 2px solid #066ac9;
}
.header-language {
  font-weight: 500;
  font-size: 18px;
  padding-left: 10px;
  cursor: pointer;
}
.header-top {
  background: #251002;
  transition: top 0.3s;
  padding: 15px 0;
  width: 100%;
  max-width: 100%;
}
.header-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.logo {
  width: 210px;
  max-width: 210px;
}
.logo-img {
  display: block;
  cursor: pointer;
}
.header-tel_top {
  display: flex;
  align-items: center;
  color: #fff;
}
.header-tel {
  display: flex;
  align-items: center;
}

.header-tel_logo {
  width: 30px;
  margin-right: 5px;
}

.header-tel_number {
  font-size: 22px;
  color: #fff;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.header-whatsapp {
  display: flex;
  align-items: center;
  margin-top: 5px;
}

.header-whatsapp_icon {
  width: 20px; 
  height: 20px;
  margin-right: 5px;
}

.header-whatsapp_text {
  color: #fff;
  font-size: 14px;
  font-weight: 600;
}

.menu-items {
  display: flex;
}

.menu-item {
  color: #fff;
  padding: 0 10px;
  font-size: 18px;
}
.menu-item:hover {
  color: #066ac9;
  transition: 0.3s;
}
.insert {
  padding: 100px 0;
  position: relative;
}
.insertBg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 100%;
}
.insert-items {
  display: grid;
  grid-template-columns: 1fr 0.8fr;
}
.insert-item {
  display: flex;
  align-items: center;
}
.insert-title {
  font-size: 50px;
  line-height: 64px;
  font-weight: 900;
  max-width: 700px;
  margin-top: -30px;
}
.insert-text {
  font-size: 21px;
  color: #2b2b2b;
  font-weight: 500;
  max-width: 1000px;
  margin-top: 20px;
  margin-bottom: 30px;
  font-family: 'Raleway', sans-serif;
}
.services {
  background-color: #f7f7f7;
  padding: 70px 0;
}
.services-title {
  text-align: left;
  font-size: 30px;
  font-weight: 700;
}
.services-hashtags {
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
}
.services-hashtag {
  margin-right: 20px;
  font-family: 'Raleway', sans-serif;
  font-size: 20px;
  font-weight: 700;
}
.services-items {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 30px;
  margin-top: 40px;
}
.services-item {
  background-color: #251002;
  padding: 30px;
  border-radius: 5px;
  height: 400px;
  cursor: pointer;
  position: relative;
}
.services-item_icon {
  position: absolute;
  bottom: 20px;
  left: 1px;
}
.services-item:hover {
  background-color: #066ac9;
  transition: 0.3s;
  box-shadow: 0 20px 25px rgba(25, 25, 25, 0.1);
}
.services-item_title {
  font-size: 22px;
  font-weight: 600;
  color: #f7f7f7;
}
.services-item_text {
  font-size: 18px;
  font-weight: 500;
  color: #d7d6d6;
  margin-top: 15px;
  line-height: 25px;
}
.services-small {
  background-color: #066ac9;
  padding: 30px;
  border-radius: 5px;
  height: 400px;
  cursor: pointer;
}
.services-small_title {
  font-size: 40px;
  font-weight: 600;
  color: #f7f7f7;
}
.services-small_text {
  font-size: 35px;
  font-weight: 500;
  color: #d7d6d6;
  margin-top: 15px;
  line-height: 50px;
}
.services-small:hover {
  background-color: #251002;
}
.about {
  padding: 70px 0;
  position: relative;
}
.aboutBg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 100%;
  z-index: -1;
}
.about-items {
  display: grid;
  grid-template-columns: 1fr 0.8fr;
}
.about-title {
  text-align: left;
  font-size: 30px;
  font-weight: 700;
  margin-top: 30px;
}
.about-text {
  margin: 10px 0;
  font-size: 18px;
  line-height: 28px;
  text-indent: 25px;
}
.about-lists {
  margin-left: 20px;
  margin-top: 10px;
}
.about-list {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}
.about-list_img {
  max-width: 30px;
  margin-right: 10px;
}
.about-list_text {
  font-size: 18px;
  font-weight: 500;
}
.bold {
  font-weight: 700;
}
.team {
  padding: 70px 0;
  background-color: #f7f7f7;
  position: relative;
}
.team-content {
  z-index: 2222;
}
.teamBg {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
  max-width: 100%;
  height: 100%;
  opacity: 0.5;
}
.teamBg2 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 100%;
  height: 100%;
  opacity: 0.1;
}
.team-text {
  font-size: 17px;
  text-indent: 27px;
  font-weight: 500;
  margin-top: 15px;
  line-height: 27px;
}
.team-items {
  margin-top: 30px;
}
.team-item {
  display: grid;
  grid-template-columns: 0.3fr 1fr;
  grid-gap: 20px;
}
.team-item_img {
  border-radius: 50%;
  max-width: 300px;
  width: 300px;
  height: 300px;
}
.team-item_name {
  font-size: 20px;
  font-weight: 700;
}
.team-item_text {
  text-indent: 25px;
  margin-top: 10px;
  font-size: 16px;
  line-height: 24px;
}
.line-block {
  width: 100%;
  max-width: 100%;
  display: flex;
  justify-content: center;
  margin: 50px 0;
}
.line {
  width: 40%;
  max-width: 40%;
  height: 3px;
  background-color: #066ac9;
  border-radius: 100%;
  /*box-shadow: 0 0 5px #066ac9;*/
  display: block;
  opacity: 0.4;
}
.work {
  padding: 70px 0;
  position: relative;
}
.workBg {
  position: absolute;
  top: 0;
  left: 0;
}
.work-items {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 40px;
  margin-top: 60px;
}
.work-photo {
  width: 120px;
  max-width: 120px;
  height: 130px;
  position: relative;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.work-item_img {
  width: 80%;
  max-width: 80%;
}
.work-item_number {
  background-color: #066ac9;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  position: absolute;
  left: -25px;
  top: -20px;
}
.work-content_name {
  font-size: 18px;
  font-weight: 600;
  margin-top: 20px;
  margin-bottom: 10px;
  text-align: center;
}
.work-content_text {
  font-size: 16px;
  text-indent: 20px;
  line-height: 24px;
}

.what {
  padding: 70px 0;
  background-color: #f7f7f7;
  position: relative;
}
.what-items {
  display: -ms-grid;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 15px;
  margin-top: 40px;
}
.what-item {
  border: 1px solid #f3f1ff;
  border-radius: 5px;
  padding: 20px 25px;
  background: #fff;
  transition: 0.3s;
  cursor: pointer;
  position: relative;
  grid-column-end: span 2;
}
.what-item:nth-last-child(-n+2) {
  grid-column-start: 2;
}
.what-item:nth-last-child(-n+1) {
  grid-column-start: 4;
}
.what-star {
  position: absolute;
  right: 10px;
  top: 10px;
  width: 20px;
  height: 20px;
}
.what-star2 {
  position: absolute;
  right: 10px;
  top: 10px;
  width: 20px;
  height: 20px;
  display: none;
}
.what-item:hover {
  box-shadow: 0 10px 15px rgba(25, 25, 25, 0.1);
  transition: 0.5s !important;
}

.what-item:hover .what-star {
  display: none !important;
}

.what-item:hover .what-star2 {
  display: block !important;
}

.what-top {
  display: flex;
  align-items: center;
}

.what-icon {
  min-width: 75px;
  max-width: 75px;
  height: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
.what-img {
  width: 50%;
  max-width: 50%;
  display: block;
}
.what-icon__color1 {
  background-color: #48ca73;
}

.what-icon__color2 {
  background-color: #d64848;
}

.what-icon__color3 {
  background-color: #ca48c3;
}

.what-icon__color4 {
  background-color: #ff930d;
}

.what-icon__color5 {
  background-color: #d4d648;
}

.what-title {
  font-size: 18px;
  font-weight: 500;
  margin-left: 30px;
}
.what-content {
  margin-top: 20px;
}
.what-text {
  font-size: 16px;
  text-indent: 25px;
  line-height: 24px;
}
.questions {
  padding: 80px 0;
  position: relative;
}
.questionsBg {
  position: absolute;
  top: 0;
  left: 0;
}
.questions-items {
  display: grid;
  grid-template-columns: 1fr 0.8fr;
}
.questions-item {
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: #fff;
}
.questions-text {
  font-size: 23px;
  font-weight: 500;
  margin-top: 20px;
  line-height: 32px;
  margin-bottom: 20px;
  text-indent: 25px;
}
.button {
  background-color: #066ac9;
  padding: 15px 20px;
  border-radius: 50px;
  display: inline-block;
  font-size: 18px;
  color: #fff;
  text-transform: uppercase;
  font-weight: 600;
  width: 300px;
  max-width: 350px;
  text-align: center;
  cursor: pointer;
}
.button:hover {
  background-color: #0623c9;
  box-shadow: 0 10px 15px rgba(25, 25, 25, 0.1);
  transition: 0.5s !important;
}
.footer {
  background-color: #251002;
  padding: 40px 0;
}
.footer-items {
  display: flex;
  justify-content: space-between;
}
.footer-item:hover {
  color: #066ac9;
  transition: 0.5s !important;
}
.footer-lists {
  display: flex;
  margin-top: 20px;
}
.footer-list {
  font-size: 18px;
  font-weight: 500;
  color: #d7d6d6;
  margin-right: 15px;
  margin-top: 15px;
}
.footer-contact {
  font-size: 18px;
  color: #d7d6d6;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.footer-media_link {
  display: flex;
  align-items: center;
}
.footer-contact_img {
  width: 25px;
  max-width: 25px;
  margin-right: 10px;
}
.footer-bottom {
  border-top: 1px solid #066ac9;
  margin-top: 30px;
  padding-top: 20px;
}
.footer-info {
  color: #d7d6d6;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
}
.footer-contact_media {
  display: flex;
}
.footer-media {
  display: flex;
  align-items: center;
  color: #d7d6d6;
  margin-right: 10px;
  cursor: pointer;
}
.footer-media_img {
  width: 20px;
  max-width: 20px;
  margin-right: 5px;
}
.header-bottom_logo {
  display: block;
}
.header-top2 {
  display: flex;
  justify-content: end;
}
.header-top_logo {
  display: none;
}
.phone_menu {
  display: none;
}
.phone_menu-icon {
  display: none;
}
.carta {
  display: grid;
  grid-template-columns: 1fr 0.7fr;
}
.carta-map {
  height: 500px;
}
.footer {
  z-index: 999999;
  display: block;
}
.carta-content {
  background-color: #066ac9;
  padding: 50px;
  max-width: 100%;
  width: 100%;
}
.carta-content_title {
  font-size: 32px;
  color: #fff;
  font-weight: 700;
  margin-bottom: 20px;
  margin-top: 20px;
}
.carta-content_address {
  font-size: 22px;
  font-weight: 700;
  color: #fff;
  margin-bottom: 5px;
}
.carta-content_city {
  font-size: 20px;
  font-weight: 500;
  color: #fff;
}
.carta-content_tel {
  font-size: 20px;
  font-weight: 500;
  color: #fff;
  margin-top: 40px;
}
.carta-content_email {
  font-size: 20px;
  font-weight: 500;
  color: #fff;
}
.language {
  display: flex;
  cursor: pointer;
  padding-left: 10px;
  font-size: 18px;
}
.language-icon {
  width: 20px;
  max-width: 20px;
}
.headerFixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 100%;
  z-index: 9999;
  background: #251002;
  transition: top 0.3s;
  padding: 15px 0;
}
@media (max-width: 1400px) {
  .services-hashtag {
    font-size: 18px;
    margin-right: 10px;
  }
  .services-hashtag:last-child {
    margin-right: 0px;
  }
  .services-items {
    grid-template-columns: 1fr 1fr;
  }
  .title {
    font-size: 30px;
  }
  .slash {
    font-size: 30px;
    width: 15px;
  }
  .questions-text {
    font-size: 20px;
  }
  .button {
    font-size: 16px;
    width: 270px;
  }
  .insert-title {
    font-size: 44px;
    line-height: 50px;
  }
  .insert-text {
    line-height: 30px;
  }
}

@media (max-width: 1200px) {
  .header-tel {
    display: none;
  }
  .services-hashtags {
    flex-wrap: wrap;
    gap: 10px;
  }
  .what-top {
    display: block;
  }
  .what-title {
    margin-left: 0;
    margin-top: 10px;
  }
  .what-content {
    margin-top: 10px;
  }
  .what-items {
    grid-template-columns: repeat(6, 1fr);
  }
  .what-item {
    grid-column-end: span 2;
  }
  .what-item:nth-last-child(-n+2) {
    grid-column-start: 2;
  }
  .what-item:nth-last-child(-n+1) {
    grid-column-start: 4;
  }
  .header-phone {
    padding-left: 10px;
    padding-right: 10px;
    border-left: 2px solid #066ac9;
  }
  .header-item_tel {
    display: flex;
  }
  .header-item_icon {
    display: block;
  }
}

@media (max-width: 1000px) {
  .header-top2 {
    justify-content: space-between;
    align-items: center;
  }
  .header-top_logo {
    display: block;
  }
  .header-top_logo .logo {
    width: 120px;
    max-width: 120px;
  }
  .insert-title {
    font-size: 33px;
    line-height: 40px;
  }
  .insert-text {
    font-size: 18px;
    line-height: 27px;
  }
  .button {
    font-size: 16px;
    width: 230px;
  }
  .title {
    font-size: 25px;
  }
  .slash {
    font-size: 25px;
    margin-right: 10px;
  }
  .services-hashtags {
    margin-top: 20px;
  }
  .services-hashtag {
    font-size: 16px;
  }
  .services-item {
    height: 350px;
  }
  .services-item_title {
    font-size: 18px;
    line-height: 27px;
  }
  .services-item_text {
    font-size: 16px;
  }
  .team-item {
    grid-template-columns: 0.4fr 1fr;
  }
  .team-item_img {
    width: 250px;
    max-width: 250px;
    height: 250px;
  }
  .what-items {
    grid-template-columns: 1fr 1fr;
  }
  .what-item {
    grid-column-end: span 1;
  }
  .what-item:nth-last-child(-n+2) {
    grid-column-start: 2;
  }
  .what-item:nth-child(5) {
    -ms-grid-column-span: 2;
    grid-column: span 2;
    width: 50%;
    max-width: 50%;
    margin: auto;
  }
  .what-top {
    display: block;
  }
  .what-title {
    margin-left: 0;
    margin-top: 10px;
  }
  .what-content {
    margin-top: 10px;
  }
  .work-items {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    margin-top: 30px;
  }
  .questions-items {
    grid-template-columns: 1fr;
  }
  .questions {
    padding-top: 150px;
  }
  .header-bottom_logo {
    display: none;
  }
  .menu-item:last-child {
    padding-left: 0 !important;
  }
  .about-lists {
    margin-left: 10px;
  }
  .about-list_text {
    font-size: 16px;
  }
  .about-list_img {
    max-width: 25px;
  }
  .footer-items {
    flex-wrap: wrap;
  }
  .footer-item {
    width: 100%;
    max-width: 100%;
  }
  .footer-lists {
    margin-bottom: 15px;
  }
  .carta-content_title {
    font-size: 28px;
  }
  .carta-content_address {
    font-size: 20px;
  }
  .carta-content_city {
    font-size: 18px;
  }
  .carta-content_tel {
    font-size: 18px;
  }
  .carta-content_email {
    font-size: 18px;
  }
  .carta-map {
    height: 400px;
  }
  .questions-item_img {
    height: 450px;
    width: 90%;
    max-width: 90%;
    margin: auto;
  }
  .services-items {
    grid-gap: 20px;
  }
}
@media (max-width: 768px) {
  .menu {
    display: none;
  }
  .phone_menu {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
  }
  .header-info {
    display: none;
  }
  .header-bottom_logo {
    display: block;
  }
  .logo {
    width: 120px;
    max-width: 120px;
  }
  .phone_menu-icon {
    width: 25px;
    max-width: 25px;
    display: block;
  }
  .about-items {
    grid-template-columns: 1fr;
  }
  .about-item_bg {
    height: 370px;
    margin: auto;
  }
  .team-item {
    grid-template-columns: 1fr;
  }
  .team-item_content {
    order: -1;
  }
  .team-text {
    font-size: 16px;
    text-indent: 25px;
    line-height: 26px;
  }
  .team-item_name {
    text-indent: 25px;
  }
  .team-item_img {
    margin: auto;
  }
  .work-photo {
    width: 100px;
    max-width: 100px;
    height: 100px
  }
  .questions {
    padding-top: 120px;
    padding-bottom: 60px;
  }
  .questions-text {
    font-size: 18px;
    line-height: 27px;
  }
  .questions-item_img {
    height: 380px;
    width: 80%;
    max-width: 80%;
    margin: auto;
  }
  .carta {
    grid-template-columns: 1fr;
  }
  .carta-map {
    height: 350px;
  }
  .carta-content {
    width: 100%;
    max-width: 100%;
    display: none;
  }
  .footer-lists {
    flex-wrap: wrap;
  }
  .footer-list {
    font-size: 16px;
  }
  .footer-logo .logo {
    max-width: 180px;
    width: 180px;
  }
  .work-items {
    grid-gap: 10px;
  }
  .services-items {
    grid-gap: 15px;
  }
}

@media (max-width: 576px) {
  .insert {
    padding: 120px 0;
    padding-bottom: 50px;
  }
  .insert-items {
    grid-template-columns: 1fr;
  }
  .insert-item_bg {
    height: 400px;
    margin: auto;
    margin-top: 30px;
  }
  .services-items {
    grid-template-columns: 1fr;
    grid-gap: 20px;
  }
  .services-item {
    height: 290px;
  }
  .questions-item_img {
    height: 320px;
  }
  .insert-title {
    text-align: center;
    font-size: 25px;
    width: 75%;
    max-width: 75%;
    margin: auto;
    line-height: 35px;
  }
  .services-item {
    height: 330px;
  }
  .insert-text {
    max-width: 90%;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    text-indent: 25px;
  }
  .insert-btn {
    width: 100%;
    max-width: 100%;
    text-align: center;
  }
  .what-items {
    grid-template-columns: 1fr;
  }
  .what-item {
    grid-column-end: span 1 !important;
  }
  .what-item:nth-last-child(-n+2) {
    grid-column-start: 1 !important;
  }
  .what-item:nth-last-child(-n+1) {
    grid-column-start: 1 !important;
  }
  .what-item:nth-child(5) {
    -ms-grid-column-span: 1;
    grid-column: span 1;
    width: 100%;
    max-width: 100%;
  }
  .questions {
    padding-top: 80px;
    padding-bottom: 60px;
  }
  .footer-lists {
    width: 80%;
    max-width: 80%;
    margin: auto;
    justify-content: center;
    flex-wrap: wrap;
  }
  .footer-logo {
    text-align: center;
    margin-bottom: 10px;
  }
  .footer-logo .logo{
    width: 200px;
    max-width: 200px;
    margin: auto;
  }
  .footer-contacts {
    margin-top: 20px;
  }
  .footer-contact {
    text-align: center;
    width: 100%;
    max-width: 100%;
    justify-content: center;
  }
  .footer-contact_media {
    justify-content: center;
  }
  .footer-contact_img {
    margin-right: 10px;
  }
  .questions-text {
    font-size: 16px;
    line-height: 25px;
  }
  .questions-btn {
    display: flex;
    justify-content: center;
  }
  .footer-list {
    margin-top: 7px;
  }
}

@media (max-width: 480px) {
  .insert-item_bg {
    height: 310px;
  }
  .services-hashtags {
    gap: 5px;
  }
  .services-item_icon {
    bottom: 5px;
  }
  .about-item_bg {
    height: 300px;
  }
  .line {
    width: 80%;
    max-width: 80%;
  }
  .work-items {
    grid-template-columns: 1fr;
  }
  .questions-item_img {
    height: 240px;
  }
  .footer-list {
    font-size: 16px;
    margin-top: 5px;
  }
  .footer-contact {
    font-size: 16px;
  }
  .footer-contact_img {
    width: 20px;
    max-width: 20px;
    margin-right: 5px;
  }
  .button {
    font-size: 14px;
    width: 210px;
  }
}